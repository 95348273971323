<template>
    <div>
        <b-row>
        <b-col><b>Roles</b></b-col>
        <b-col><b-button variant="primary" @click="onAddRole(roles)">Add role</b-button></b-col>
        </b-row>
        <br />
        <b-form inline v-for="(role, index) in roles" :key="index">
            <div>
                <b-form-input placeholder="Name" v-model="role.name" class="m-2"/><br />
            </div>
            <div>
                <b-form-input placeholder="Rate" v-model="role.rate" class="m-2" /><br />
            </div>
            <b-button variant="primary" @click="onDeleteRole(role, roles.indexOf(role))">Delete</b-button>
            <b-button @click="onSaveRole(role)" variant="primary" class="m-1">Save</b-button>
        </b-form>
        <span v-if="Object.keys( errors ).length != 0" class="error">
            {{ errors }}
        </span>
    </div>
</template>


<script>

import confirmationModal from '@/api/confirmation'
import crudMixin from '@/api/crud'
import { deleteConsultingRole, updateConsultingRole, addConsultingRole } from '@/api/consulting.api'
import Vue from 'vue'


export default {
    mixins: [crudMixin, confirmationModal],
    props : {
        roles : Array,
        project: Object
    },
    data() {
        return {
            id: this.project.id
        }
    },
    methods:{
        onAddRole: function(roles) {
            roles.push({'project': {'id': this.project.id}})
        },
        onDeleteRole: function(role, index) {
            if (role.id) {
                this.roleId = role.id
                // confirmModal(message, okTitle, cancelTitle)
                this.confirmModal().then((result) => {
                if (result){
                    this.deleteObject(
                        deleteConsultingRole(this.roleId),
                        () => {
                            this.roles.splice(index, 1)
                        }
                    )
                }
            })
            }
        },
        onSaveRole: function(role){
            if (role.id) {
                var apiCall = updateConsultingRole(role.id, role)
            } else {
                apiCall = addConsultingRole(role)
            }
            this.saveObject(apiCall, (res) => {
                var location = res.data.location
                Vue.set( role, 'id', parseInt(location.substring(location.lastIndexOf('/') + 1)))
            })
        }
    }
}
</script>